import { Asterisk, PencilIcon, TrashIcon, Variable } from "lucide-react";

import { FC } from "react";
import { FormBuilderComponentField } from "./FormBuilderComponentField";
import { FormComponentProps } from "@amenda-types";
import { FormComponentTypes } from "@amenda-constants";
import { IconButtonBase } from "@amenda-components/App";
import isEmpty from "lodash/isEmpty";
import { useAppStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

export const FormBuilderComponent: FC<FormComponentProps> = ({
  config,
  global,
}) => {
  const { t } = useTranslation();
  const updateFormBuilderState = useAppStore(
    (state) => state.updateFormBuilderState,
  );

  const componentLabel =
    config?.properties?.readOnlyLabel ??
    config?.properties?.label ??
    config?.id;
  const componentType = config?.component as FormComponentTypes;
  const isRequired = Array.isArray(config?.validation?.properties)
    ? (config?.validation?.properties ?? []).some((c: any) =>
        Boolean(c?.then?.required ?? c?.otherwise?.required),
      )
    : Boolean(config?.validation?.properties?.required);
  const hasDisplayCondition = !isEmpty(config?.display);

  const handleOpen = () => {
    updateFormBuilderState("openEditComponentModal", true);
    updateFormBuilderState("selectedFormComponent", {
      config,
      global,
    });
  };

  const handleOpenDeleteModal = () => {
    updateFormBuilderState("deleteComponent", true);
    updateFormBuilderState("selectedFormComponent", {
      config,
      global,
    });
  };

  return (
    <div className="relative h-full w-full overflow-hidden p-1">
      <div className="flex w-full items-center justify-between truncate">
        <div className="flex items-start">
          {hasDisplayCondition && (
            <Variable className="h-4 w-4 text-gray-900" />
          )}
          {FormComponentTypes.Title === componentType ? (
            <div className="flex items-center text-sm">
              <span className="pr-1">{t("Title")}:</span>
              <span className="italic">{componentLabel}</span>
            </div>
          ) : (
            <span className="text-sm">{componentLabel}</span>
          )}
          {isRequired && <Asterisk className="h-4 w-4 text-red-600" />}
        </div>
        <div className="invisible absolute right-0 flex items-center justify-end space-x-1 bg-white group-hover/full-col:visible group-hover/half-col:visible group-hover/one-third-col:visible group-hover/two-thirds-col:visible">
          <IconButtonBase
            size="xs"
            variant="clean"
            className="h-5 w-5"
            onClick={handleOpen}
          >
            <PencilIcon className="h-4 w-4" />
          </IconButtonBase>
          {!Boolean(config?.isAppSpecific) && (
            <IconButtonBase
              size="xs"
              variant="danger"
              className="h-5 w-5"
              onClick={handleOpenDeleteModal}
            >
              <TrashIcon className="h-4 w-4" />
            </IconButtonBase>
          )}
        </div>
      </div>
      <FormBuilderComponentField config={config} global={global} />
      {Boolean(config?.isAppSpecific) && (
        <div className="mt-1 w-full text-xs text-red-600">
          {t("Amenda required")}
        </div>
      )}
    </div>
  );
};
