import { FC, useEffect, useState } from "react";
import { formatDate, formatQuarter } from "@amenda-utils";

import { Avatar } from "@amenda-components/App";
import clsx from "clsx";
import { getUserName } from "@amenda-components/Contacts/common";
import { isEmpty } from "lodash";
import { useGetBKIValue } from "@amenda-domains/queries";
import { useTranslation } from "react-i18next";

interface Props {
  users: any[];
  option: any;
  selected: boolean;
}

const CostGroupAvatar: FC<Props> = ({ users, option, selected }) => {
  const user = users.find((user) => user?.id === option?.ownerId);

  if (isEmpty(user)) return null;
  return (
    <Avatar
      name={getUserName(user)}
      src={user?.photoURL}
      className={clsx("mr-1 h-6 min-h-6 w-6 min-w-6 text-sm", {
        "group-hover:bg-white group-hover:text-gray-800": selected,
      })}
    />
  );
};

export const CostGroupSelectItem: FC<Props> = ({ option, users, selected }) => {
  const { t } = useTranslation();
  const { getBKIValue, loading } = useGetBKIValue();
  const [bkiValue, setBKIValue] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (option?.bkiDate) {
      getBKIValue({
        date: option.bkiDate,
        callback: setBKIValue,
      });
    }

    return () => {
      setBKIValue(undefined);
    };
  }, [option?.bkiDate, getBKIValue]);

  return (
    <div className="w-full">
      <div className="group flex w-full">
        <CostGroupAvatar users={users} option={option} selected={selected} />
        <div className="flex w-full flex-col space-y-1 truncate text-left text-xs transition duration-75 ease-in-out group-hover:text-wrap">
          <span>
            {Boolean(option?.unavailable)
              ? t(option.label)
              : t("CostGroupSelectItem", {
                  bkiValue: loading ? "..." : bkiValue,
                  versionDate: option.value ? formatDate(option.value) : "",
                  bkiDate: option?.bkiDate ? formatQuarter(option.bkiDate) : "",
                })}
          </span>
          {option?.description && <span>{option?.description}</span>}
        </div>
      </div>
    </div>
  );
};
