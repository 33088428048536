import { AllowedContactType } from "@amenda-types";
import { FC } from "react";
import { Tooltip } from "@amenda-components/App";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface Props {
  contact: any;
  className?: string;
  hideTooltip?: boolean;
}

export const DeactivatedUsersAlert: FC<Props> = ({
  contact,
  className = "text-sm",
  hideTooltip = false,
}) => {
  const { t } = useTranslation();
  const isDeactivated =
    (!Boolean(contact?.type) || contact?.type === AllowedContactType.office) &&
    !Boolean(contact?.isActive);

  if (!isDeactivated) {
    return null;
  }
  return (
    <Tooltip
      id={`contactDeactivated-${contact?.id}`}
      positionStrategy="fixed"
      position="top"
      message={hideTooltip ? "" : "This contact is not activated"}
      className="flex w-fit items-center rounded-lg"
    >
      <span
        className={clsx("z-10 leading-none text-gray-400", className, {
          "cursor-pointer": !hideTooltip,
        })}
      >
        {t("Inactive")}
      </span>
    </Tooltip>
  );
};
