import {
  ContactListFooter,
  ContactListHeader,
  ContactListView,
} from "@amenda-components/Contacts";
import {
  ContactSearchProps,
  getContactRequestArgs,
  hasNoUserSearchAndGrouping,
  isUserSelected,
} from "./common";
import { FC, useCallback, useRef } from "react";
import { useFormStore, useUsersStore } from "@amenda-domains/mutations";
import { useNavigate, useParams } from "react-router-dom";

import { ContactDirectoryProps } from "./types";
import { DebounceTimes } from "@amenda-constants";
import { SidebarContainer } from "@amenda-components/Shared";
import { SlideOver } from "@amenda-components/App";
import { abortRequest } from "@amenda-domains/abortExchange";
import { debounce } from "lodash";

interface Props extends ContactDirectoryProps {
  users: any[];
  groupedUsers: Record<string, any>;
  selectedUser?: any;
  isCollection: boolean;
}

type ContactListHeaderWrapperProps = ContactDirectoryProps & {
  isCollection: boolean;
};

const ContactListHeaderWrapper: FC<ContactListHeaderWrapperProps> = ({
  isCollection,
  ...props
}) => {
  const { userId } = useParams<{ userId: string }>();
  const { collectionId } = useParams<{ collectionId?: string }>();
  const navigate = useNavigate();
  const selectedCollectionByType = useFormStore(
    (state) => state.selectedCollectionByType,
  );
  const userActivationState = useUsersStore(
    (state) => state.userActivationState,
  );

  const { getAllContacts, searchContacts, collectionType } = props;
  const selectedCollection = selectedCollectionByType[collectionType];

  const navigatePath = useCallback(() => {
    if (userId) {
      const path = isCollection
        ? `${props.collectionRoute}/${collectionId}`
        : props.rootRoute;
      navigate(path);
    }
  }, [
    userId,
    collectionId,
    isCollection,
    props.rootRoute,
    props.collectionRoute,
    navigate,
  ]);

  const debounceSearch = useRef(
    debounce(
      async ({
        collection,
        ...rest
      }: ContactSearchProps & {
        navigatePath: () => void;
      }) => {
        const args: any = getContactRequestArgs({
          isCollection,
          userActivationState,
          autoSelect: true,
          defaultContactType: props.defaultContactType,
          collectionResourceIds: collection?.resourceIds,
          ...rest,
        });

        navigatePath();
        if (!hasNoUserSearchAndGrouping(rest)) {
          await searchContacts(args);
        } else {
          abortRequest("searchUsers");
          await getAllContacts({
            ...args,
            context: {
              requestPolicy: "network-only",
            },
          });
        }
      },
      DebounceTimes.Search,
    ),
  ).current;

  const runSearch = async (args: ContactSearchProps) => {
    await debounceSearch({
      ...args,
      navigatePath,
      collection: selectedCollection,
    });
  };

  return (
    <ContactListHeader
      isCollection={isCollection}
      handleSearch={runSearch}
      {...props}
    />
  );
};

export const ContactList: FC<Props> = ({
  users,
  selectedUser,
  groupedUsers,
  ...props
}) => {
  const isSelectedContact = (c: any) => isUserSelected(c, selectedUser);

  return (
    <SidebarContainer className="w-72 border-r lg:w-96">
      <ContactListHeaderWrapper {...props} />
      <ContactListView
        users={users}
        groupedContacts={groupedUsers}
        isSelectedContact={isSelectedContact}
        {...props}
      />
      <ContactListFooter {...props} />
    </SidebarContainer>
  );
};

export const ContactListMobile: FC<Props> = ({
  users,
  selectedUser,
  groupedUsers,
  ...props
}) => {
  const isSelectedContact = (c: any) => isUserSelected(c, selectedUser);
  const openContactListSlideOver = useUsersStore(
    (state) => state.openContactListSlideOver,
  );
  const setOpenContactListSlideOver = useUsersStore(
    (state) => state.setOpenContactListSlideOver,
  );

  const handleClose = () => {
    setOpenContactListSlideOver(false);
  };

  return (
    <SlideOver show={openContactListSlideOver} onClose={handleClose}>
      <div className="relative h-screen w-full overflow-y-auto">
        <div className="sticky top-0 z-20 w-full bg-white">
          <ContactListHeaderWrapper {...props} />
        </div>
        <ContactListView
          users={users}
          groupedContacts={groupedUsers}
          isSelectedContact={isSelectedContact}
          {...props}
        />
        <ContactListFooter {...props} />
      </div>
    </SlideOver>
  );
};
