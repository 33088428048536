import { FC, useEffect, useRef, useState } from "react";
import {
  customStyling,
  selectMenuOverflow,
  selectMenuOverflowProps,
} from "@amenda-styles/customStyling";
import { getContactRequestArgs, getUserName } from "./common";
import {
  useGetContact,
  useGetUser,
  useSearchContacts,
  useSearchUsers,
} from "@amenda-domains/queries";

import { AllowedContactType } from "@amenda-types";
import AsyncSelect from "react-select/async";
import { ChevronDown } from "lucide-react";
import { DebounceTimes } from "@amenda-constants";
import { ErrorMessage } from "@amenda-components/FormComponents";
import clsx from "clsx";
import { components } from "react-select";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import { useUsersStore } from "@amenda-domains/mutations";

interface Props {
  contactType: AllowedContactType;
  disabled?: boolean;
  error?: string;
  hasMenuOverflow?: boolean;
  hideErrorMessage?: boolean;
  id?: string;
  label?: string;
  optional?: boolean;
  contactId?: string;
  shouldReturnId?: boolean;
  onChange?: (value: any) => void;
}

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDown
        className={clsx("h-5 w-5 text-gray-400", {
          "rotate-180": props.selectProps.menuIsOpen,
        })}
        aria-hidden="true"
      />
    </components.DropdownIndicator>
  );
};

export const SidebarSearchContactByType: FC<Props> = ({
  label,
  error,
  contactType,
  id,
  hideErrorMessage,
  optional,
  contactId,
  disabled = false,
  hasMenuOverflow = true,
  onChange = () => {},
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const { searchUsers, loading: loadingSearchUsers } = useSearchUsers();
  const { searchContacts, loading: loadingSearchContacts } =
    useSearchContacts();
  const { getUser, loading: loadingGetUser } = useGetUser();
  const { getContact, loading: loadingGetContact } = useGetContact();
  const [selectedUser, setSelectedUser] = useState<Record<string, any>>();
  const userActivationState = useUsersStore(
    (state) => state.userActivationState,
  );

  const isSearching = loadingSearchUsers || loadingSearchContacts;
  const isDisabled = disabled || loadingGetUser || loadingGetContact;

  const debouncedSearch = useRef(
    debounce(
      async (
        args: { searchTerm: string } & any,
        resolve: (users: any[]) => void,
      ) => {
        const cb =
          args.type === AllowedContactType.office
            ? searchUsers
            : searchContacts;
        await cb({
          ...args,
          callback: resolve,
        });
      },
      DebounceTimes.Search,
    ),
  ).current;

  const loadOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      if (inputValue.length > 1) {
        const args = getContactRequestArgs({
          contactType,
          userActivationState,
          autoSelect: false,
          isCollection: false,
          searchTerm: inputValue,
        });

        await debouncedSearch(args, resolve);
      }
    });

  useEffect(() => {
    const getContactAsync = async () => {
      const cb =
        contactType === AllowedContactType.office ? getUser : getContact;

      if (contactId) {
        await cb({
          id: contactId,
          callback: setSelectedUser,
        });
      }
    };

    getContactAsync();
  }, [contactId, contactType, getUser, getContact]);

  return (
    <div className="w-full" ref={ref}>
      <div className="flex justify-between">
        {label && (
          <label htmlFor={id} className="amenda-component-label">
            {t(label)}
          </label>
        )}
        {optional && (
          <span className="text-sm text-gray-500" id="email-optional">
            {t("Optional")}
          </span>
        )}
      </div>
      <div className="w-full">
        <AsyncSelect
          id={id}
          cacheOptions
          value={selectedUser}
          menuPlacement="auto"
          isClearable={true}
          isDisabled={isDisabled}
          placeholder={t("Search Contacts")}
          loadingMessage={() => t("Loading") + "..."}
          className={customStyling.select.containerClass}
          isLoading={isSearching}
          getOptionValue={(option: any) => option.id}
          getOptionLabel={getUserName}
          loadOptions={loadOptions}
          noOptionsMessage={({ inputValue }) =>
            inputValue ? t("No results found") : t("Start typing")
          }
          onChange={(v: any) => {
            const value = v?.id ? [v?.id] : v;

            onChange(value);
          }}
          components={{
            DropdownIndicator,
          }}
          styles={{
            ...customStyling.select.styleOverride,
            ...selectMenuOverflow(hasMenuOverflow, ref),
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
          })}
          {...selectMenuOverflowProps(hasMenuOverflow)}
        />
      </div>
      {!hideErrorMessage && <ErrorMessage id={id} error={error} />}
    </div>
  );
};
