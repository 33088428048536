import { FC, useCallback } from "react";
import {
  GroupByKeysView,
  GroupView,
  ListView,
  ListWrapper,
} from "@amenda-components/Shared";
import { HelperMessage, Spinner } from "@amenda-components/App";
import { isSafeCollection, useVerticalScrollEvent } from "@amenda-utils";
import { useFormStore, useMaterialsStore } from "@amenda-domains/mutations";

import { AllowedCollectionType } from "@amenda-types";
import { CubeIcon } from "@heroicons/react/24/outline";
import { MaterialCard } from "./MaterialCard";
import { MaterialsPageProps } from "./common";
import { PaginationLimit } from "@amenda-constants";
import isEmpty from "lodash/isEmpty";
import { useGetAllMaterials } from "@amenda-domains/queries";
import { useInView } from "react-intersection-observer";
import { useParams } from "react-router-dom";

export const MaterialListView: FC<MaterialsPageProps> = (props) => {
  const pagination = useMaterialsStore((state) => state.pagination);
  const { getAllMaterials, loading: fetchingMore } = useGetAllMaterials();
  const { materialId } = useParams<{ materialId: string }>();
  const defaultSelectedMaterialId = useMaterialsStore(
    (state) => state.selectedMaterialId,
  );
  const searchTerm = useMaterialsStore((state) => state.searchTerm);
  const groupingComponents = useMaterialsStore(
    (state) => state.groupingComponents,
  );
  const materials = useMaterialsStore((state) => state.materials);
  const groupedMaterials = useMaterialsStore((state) => state.groupedMaterials);
  const { ref, inView } = useInView({
    skip: isEmpty(groupedMaterials),
  });
  const selectedCollectionByType = useFormStore(
    (state) => state.selectedCollectionByType,
  );
  const handleVerticalScroll = useVerticalScrollEvent();
  const isSearchingMaterials = useMaterialsStore(
    (state) => state.isSearchingMaterials,
  );
  const isFetchingMaterials = useMaterialsStore(
    (state) => state.isFetchingMaterials,
  );

  const selectedMaterialId = materialId ?? defaultSelectedMaterialId;
  const selectedCollection =
    selectedCollectionByType[AllowedCollectionType.Materials];
  const isSelected = (material: any) => material.id === selectedMaterialId;

  const handleNextPage = useCallback(() => {
    if (
      inView &&
      !fetchingMore &&
      pagination?.hasNext &&
      isEmpty(searchTerm) &&
      isEmpty(groupingComponents) &&
      isSafeCollection(props.isCollection, selectedCollection?.resourceIds)
    ) {
      const args: Record<string, any> = {
        isDeleted: false,
        autoSelect: false,
        next: pagination?.next,
        limit: PaginationLimit.contacts,
      };
      if (props.isCollection) {
        args.isCollection = true;
        args.ids = selectedCollection?.resourceIds;
      }

      getAllMaterials(args);
    }
  }, [
    inView,
    searchTerm,
    fetchingMore,
    props.isCollection,
    pagination?.hasNext,
    pagination?.next,
    groupingComponents,
    selectedCollection?.resourceIds,
    getAllMaterials,
  ]);

  return (
    <nav
      className="min-h-0 w-full flex-1 overflow-y-auto"
      aria-label="Directory"
      onScroll={handleVerticalScroll(handleNextPage)}
    >
      {isSearchingMaterials ? (
        <div className="flex flex-row justify-center py-4">
          <Spinner spinnerSize="sm" variant="secondary" />
        </div>
      ) : isEmpty(groupedMaterials) ? (
        <HelperMessage Icon={CubeIcon} message="No materials found" />
      ) : (
        <>
          {!isEmpty(groupingComponents) ? (
            <GroupByKeysView
              label="Materials"
              field="materials"
              resources={materials}
              components={groupingComponents}
              isSelected={isSelected}
            >
              {(r) => <MaterialCard material={r} {...props} />}
            </GroupByKeysView>
          ) : searchTerm && searchTerm.length >= 1 ? (
            <ListWrapper>
              <ListView resources={materials} isSelected={isSelected}>
                {(r) => <MaterialCard material={r} {...props} />}
              </ListView>
            </ListWrapper>
          ) : (
            <GroupView
              groupedResources={groupedMaterials}
              isSelected={isSelected}
            >
              {(r) => <MaterialCard material={r} {...props} />}
            </GroupView>
          )}
        </>
      )}
      {isFetchingMaterials && (
        <div className="flex flex-row justify-center py-4">
          <Spinner spinnerSize="sm" variant="secondary" />
        </div>
      )}
      <div ref={ref} className="h-12" />
    </nav>
  );
};
