import {
  CalculatorIcon,
  ChevronDownIcon,
  EllipsisVerticalIcon,
  PlusIcon,
  VariableIcon,
} from "@heroicons/react/24/outline";
import { FC, SyntheticEvent, useState } from "react";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";

import { AggregateOption } from "./common";
import { Divider } from "@amenda-components/App";
import Fuse from "fuse.js";
import { MiniSearchField } from "@amenda-components/SearchComponents";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface Props {
  options: AggregateOption[];
  onChange: (option: AggregateOption) => void;
  withIcon?: boolean;
}

export const AggregationPicker: FC<Props> = ({
  options,
  withIcon = false,
  onChange,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [availableOptions, setAvailableOptions] =
    useState<AggregateOption[]>(options);

  const handleClick =
    (option: AggregateOption, close: () => void) =>
    (e: SyntheticEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      onChange(option);
      close();
    };

  const handleSearch = (value: string) => {
    let filteredOptions = [...options];
    if (value) {
      const fuse = new Fuse(options, {
        includeScore: true,
        shouldSort: true,
        keys: ["value", "label"],
      });
      const results = fuse.search(value);
      filteredOptions = results.map((res) => res.item);
    }
    setSearchTerm(value);
    setAvailableOptions(filteredOptions);
  };

  return (
    <Popover as="div" className="relative flex w-full justify-end">
      <PopoverButton
        className={clsx({
          "flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100":
            withIcon,
          "group inline-flex items-center rounded-2xl border border-gray-900 bg-white px-2 py-[1px] text-xxs text-gray-900 hover:bg-gray-900 hover:text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75":
            !withIcon,
        })}
      >
        {withIcon ? (
          <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
        ) : (
          <>
            <PlusIcon className="mr-1 h-3 w-3" />
            <span className="pt-[3px]">{t("Add Widget")}</span>
            <Divider />
            <ChevronDownIcon className="ml-1 h-2 w-2" aria-hidden="true" />
          </>
        )}
      </PopoverButton>
      <Transition
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <PopoverPanel
          as="div"
          className="absolute z-50 mt-6 w-72 origin-top-right divide-y divide-gray-100 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          {({ close }) => (
            <div className="max-h-96 overflow-auto">
              <div className="border-1 sticky top-0 mb-2 border-b bg-white px-2 py-2 text-xs text-gray-500">
                <MiniSearchField
                  value={searchTerm}
                  className="border"
                  placeholder={t("Search for a widget") + "..."}
                  onChange={handleSearch}
                />
              </div>
              {availableOptions.map((option) => (
                <div
                  key={option.value}
                  onClick={handleClick(option, close)}
                  className="group flex cursor-pointer text-gray-700 hover:bg-gray-900 hover:text-white"
                >
                  <div className="flex w-full flex-row items-center space-x-2 px-2 py-2">
                    <div>
                      {option.type === "formulae" ? (
                        <CalculatorIcon className="h-4 w-4" />
                      ) : (
                        <VariableIcon className="h-4 w-4" />
                      )}
                    </div>
                    <div className="text-sm">{t(option.label)}</div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </PopoverPanel>
      </Transition>
    </Popover>
  );
};
