import { FC, useRef, useState } from "react";
import {
  FormComponentProps,
  SimilaritySearchConfigColumns,
} from "@amenda-types";
import { IconButtonBase, SimilarityRangeSlider } from "@amenda-components/App";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import {
  SimilarityConfigKey,
  SimilaritySearchDefaultValues,
  getMatchTypeOptions,
} from "./common";
import { useProjectStore, useTenantStore } from "@amenda-domains/mutations";

import { Controller } from "react-hook-form";
import { FormComponentTypes } from "@amenda-constants";
import { ReactSVG } from "react-svg";
import { SingleSelect } from "@amenda-components/FormComponents";
import { XIcon } from "lucide-react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const iconClassName =
  "invisible group-hover/textField:visible group-hover/textArea:visible group-hover/addressSearch:visible group-hover/datePicker:visible group-hover/datePickerRange:visible group-hover/reactSelect:visible group-hover/headlessContacts:visible group-hover/regionalSelect:visible group-hover/reactSelectCreatable:visible group-hover/searchProjects:visible group-hover/switch:visible";

const getId = (id: string, path: string) => {
  return `${id}.${path}`;
};

const SimilarityConfigurator: FC<FormComponentProps> = ({ config, global }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const primaryTenant = useTenantStore((state) => state.primaryTenant);

  const { id, formId, component } = config;
  const similaritySearchConfig =
    primaryTenant?.settings?.similaritySearchConfig || {};
  const componentId = id?.replace(SimilarityConfigKey, "");
  const values = similaritySearchConfig?.[formId]?.[componentId];
  const isMulti = [
    FormComponentTypes.MultiSelect,
    FormComponentTypes.Keyword,
    FormComponentTypes.Badges,
    FormComponentTypes.Checkbox,
  ].includes(component as FormComponentTypes);
  const fieldId = `${id}${SimilarityConfigKey}`;

  const togglePopover = () => {
    if (!isOpen) {
      setIsOpen(true);
      buttonRef?.current?.click();
    }
  };

  if (!global.control) return null;

  return (
    <Popover as="div" className="relative" onMouseEnter={togglePopover}>
      {() => (
        <>
          <div className="flex items-center">
            <PopoverButton
              ref={buttonRef}
              className={clsx(
                "flex flex-row items-center p-0.5 text-gray-900 outline-none hover:bg-gray-900 hover:text-white",
                iconClassName,
              )}
            >
              <ReactSVG
                src="/svg/similarity_search_icon.svg"
                className="h-5 w-5"
              />
            </PopoverButton>
          </div>
          <Transition
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <PopoverPanel
              as="div"
              className="fixed z-50 w-80 origin-top-left divide-y divide-gray-100 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              {({ close }) => (
                <div className="max-h-80 min-h-[10rem] w-full overflow-auto px-4 pb-8 pt-4">
                  <div className="mb-2 flex items-center justify-between text-sm text-gray-900">
                    <span>{t("Configure search")}</span>
                    <IconButtonBase
                      onClick={() => {
                        close();
                        setIsOpen(false);
                      }}
                    >
                      <XIcon className="h-5 w-5" />
                    </IconButtonBase>
                  </div>
                  <div className="w-full space-y-3">
                    <Controller
                      name={getId(fieldId, SimilaritySearchConfigColumns.Match)}
                      control={global.control}
                      defaultValue={
                        values?.[SimilaritySearchConfigColumns.Match] ||
                        SimilaritySearchDefaultValues.matchType
                      }
                      render={({ field: { value, onChange } }) => {
                        return (
                          <div
                            className="flex h-full flex-col"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <span className="amenda-component-label mb-1 !text-sm">
                              {t("Match")}
                            </span>
                            <SingleSelect
                              id={getId(fieldId, "matchType")}
                              className="w-full"
                              isClearable={false}
                              hasMenuOverflow={true}
                              hideErrorMessage={true}
                              value={value}
                              options={getMatchTypeOptions(isMulti)}
                              onChange={onChange}
                              getOptionLabel={(option) => t(option.label)}
                            />
                          </div>
                        );
                      }}
                    />
                    <Controller
                      name={getId(
                        fieldId,
                        SimilaritySearchConfigColumns.Weight,
                      )}
                      control={global.control}
                      defaultValue={
                        values?.[SimilaritySearchConfigColumns.Weight] ||
                        SimilaritySearchDefaultValues.weight
                      }
                      render={({ field: { value, onChange } }) => {
                        return (
                          <div className="flex h-full flex-col justify-center xl:pr-2">
                            <span className="amenda-component-label mb-1 !text-sm">
                              {t("Weight")}
                            </span>
                            <SimilarityRangeSlider
                              mini={true}
                              range={value}
                              className="w-full"
                              onChange={onChange}
                            />
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
              )}
            </PopoverPanel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export const SimilaritySearchPopover: FC<
  FormComponentProps & {
    isSimilaritySearch: boolean;
  }
> = ({ isSimilaritySearch, ...rest }) => {
  const { config } = rest;
  const { properties } = config;
  const toggleSimilaritySearchComponent = useProjectStore(
    (state) => state.toggleSimilaritySearchComponent,
  );

  const handleDelete = () => toggleSimilaritySearchComponent(config);

  if (!isSimilaritySearch) {
    return properties?.optional ? <>{properties.optional}</> : null;
  }

  return (
    <div className="flex items-center space-x-3 pb-0.5">
      <SimilarityConfigurator {...rest} />
      <IconButtonBase
        className={iconClassName}
        label="Remove component"
        variant="clean"
        onClick={handleDelete}
      >
        <XMarkIcon className="h-6 w-6" />
      </IconButtonBase>
    </div>
  );
};
