import { Divider, ModalBarebones } from "@amenda-components/App";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Option, PageComponentProps } from "@amenda-types";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";

import { ChevronRightIcon } from "@heroicons/react/24/outline";
import Fuse from "fuse.js";
import { MiniSearchField } from "@amenda-components/SearchComponents";
import clsx from "clsx";
import { groupBy } from "lodash";
import { useTranslation } from "react-i18next";

type OptionType = PageComponentProps & Option;

export interface SearchComponentItemProps {
  position: number;
  formLabel: string;
  options: OptionType[];
  close: () => void;
  clearSearchTerm: () => void;
}

interface Props {
  label: string;
  disabled?: boolean;
  components: PageComponentProps[];
  formNameById: Record<string, string>;
  Icon?: FC<any>;
  className?: string;
  iconClassName?: string;
  SearchComponentItem: FC<SearchComponentItemProps>;
}

const ComponentSearchBody: FC<
  Pick<Props, "components" | "formNameById" | "SearchComponentItem"> & {
    close: () => void;
  }
> = ({ components, formNameById, SearchComponentItem, close }) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [componentsByForm, setComponentsByForm] = useState<
    Record<string, OptionType[]>
  >({});

  const options: OptionType[] = useMemo(() => {
    return components.map((c) => ({
      ...c,
      value: c.id,
      label:
        ((c.properties?.label || c.properties?.readOnlyLabel) as string) ||
        c.id,
    }));
  }, [components]);

  useEffect(() => {
    const groupedComponents = groupBy(options, "formId");
    setComponentsByForm(groupedComponents);
  }, [options]);

  const handleClear = () => setSearchTerm("");

  const handleSearch = (value: string) => {
    let filteredComponents = [...options];
    if (value) {
      const fuse = new Fuse(options, {
        includeScore: true,
        shouldSort: true,
        threshold: 0.3,
        keys: [
          {
            name: "label",
            getFn: (component: OptionType) =>
              (component.properties?.label as string) || "",
          },
          {
            name: "placeholder",
            getFn: (component: OptionType) =>
              (component.properties?.placeholder as string) || "",
          },
        ],
      });
      const results = fuse.search(value);
      filteredComponents = results.map((res) => res.item);
    }
    const componentsByForm = groupBy(filteredComponents, "formId");
    setComponentsByForm(componentsByForm);
    setSearchTerm(value);
  };

  return (
    <div className="mt-2 max-h-80 w-full overflow-y-auto border border-gray-100">
      {Object.keys(componentsByForm).map((key, i) => {
        return (
          <SearchComponentItem
            key={key}
            position={i}
            formLabel={formNameById[key] || ""}
            options={componentsByForm[key]}
            close={close}
            clearSearchTerm={handleClear}
          />
        );
      })}
      <div className="border-1 sticky bottom-0 bg-white px-2 py-2 text-xs text-gray-500">
        <MiniSearchField
          value={searchTerm}
          className="w-full border"
          placeholder={t("Search for Parameter") + "..."}
          onChange={handleSearch}
        />
      </div>
    </div>
  );
};

export const ComponentSearchModal: FC<Props> = ({
  Icon,
  label,
  components,
  formNameById,
  SearchComponentItem,
  className,
  disabled = false,
  iconClassName = "h-4 w-4",
}: Props) => {
  const ref = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, []);

  const clientRect = ref.current?.getBoundingClientRect();
  const maxHeightWithPadding = 340;
  const left = Number(clientRect?.left);
  let top = window.innerHeight - Number(clientRect?.top);
  top = top > 0 ? Math.min(top, window.innerHeight - maxHeightWithPadding) : 0;

  return (
    <div>
      <button
        ref={ref}
        className={clsx(
          "group inline-flex items-center hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75",
          className,
          {
            "cursor-not-allowed": disabled,
          },
        )}
        type="button"
        disabled={disabled}
        onClick={handleOpen}
      >
        {Icon && <Icon className="mr-1 h-4 w-4" />}
        <span className="pt-[3px]">{t(label)}</span>
        <Divider />
        <ChevronRightIcon
          className={clsx("ml-1", iconClassName, {
            "rotate-90": open,
          })}
          aria-hidden="true"
        />
      </button>
      <ModalBarebones
        isOpen={open}
        onClose={handleClose}
        className="fixed"
        style={{
          bottom: top + window.scrollY,
          left: left + window.scrollX,
        }}
      >
        <ComponentSearchBody
          components={components}
          formNameById={formNameById}
          SearchComponentItem={SearchComponentItem}
          close={handleClose}
        />
      </ModalBarebones>
    </div>
  );
};

export const ComponentDropdownSearch: FC<Props> = ({
  Icon,
  label,
  components,
  formNameById,
  SearchComponentItem,
  className,
  disabled = false,
  iconClassName = "h-4 w-4",
}) => {
  const { t } = useTranslation();

  return (
    <Popover as="div" className="relative">
      {({ open }) => (
        <>
          <PopoverButton
            className={clsx(
              "group inline-flex items-center hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75",
              className,
              {
                "cursor-not-allowed": disabled,
              },
            )}
            disabled={disabled}
          >
            <>
              {Icon && <Icon className="mr-1 h-4 w-4" />}
              <span className="pt-[3px]">{t(label)}</span>
              <Divider />
              <ChevronRightIcon
                className={clsx("ml-1", iconClassName, {
                  "rotate-90": open,
                })}
                aria-hidden="true"
              />
            </>
          </PopoverButton>
          <Transition
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <PopoverPanel
              as="div"
              className="absolute z-40 mt-1 w-72 origin-top-right divide-y divide-gray-100 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              {({ close }) => (
                <ComponentSearchBody
                  components={components}
                  formNameById={formNameById}
                  SearchComponentItem={SearchComponentItem}
                  close={close}
                />
              )}
            </PopoverPanel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
