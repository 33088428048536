import { AllowedAttachmentType, AllowedCollectionType } from "@amenda-types";
import { DebounceTimes, PaginationLimit } from "@amenda-constants";
import { FC, useCallback, useRef } from "react";
import {
  isSearchFilterEmpty,
  transformFilters,
} from "@amenda-components/Shared/common";
import {
  useAttachmentStore,
  useFormStore,
  useSidebarFiltersWithPath,
} from "@amenda-domains/mutations";
import {
  useGetAllAttachments,
  useSearchAttachments,
} from "@amenda-domains/queries";

import { ConstructionDetails } from "@amenda-components/ConstructionDetails";
import { Helmet } from "react-helmet-async";
import { MaxWidthLayout } from "@amenda-components/Shared";
import { abortRequest } from "@amenda-domains/abortExchange";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import { useLocation } from "react-router-dom";
import { useRunOnMountOnly } from "@amenda-utils";
import { useTranslation } from "react-i18next";

interface Props {
  isCollection?: boolean;
  collectionType: AllowedCollectionType;
}

export const ConstructionDetailsPageWrapper: FC<Props> = ({
  collectionType,
  isCollection = false,
}) => {
  const { t } = useTranslation();
  const attachments = useAttachmentStore((state) => state.attachments);
  const pagination = useAttachmentStore((state) => state.pagination);
  const setIsSearching = useAttachmentStore((state) => state.setIsSearching);
  const { searchAttachments } = useSearchAttachments();
  const selectedCollectionByType = useFormStore(
    (state) => state.selectedCollectionByType,
  );
  const { getAllAttachments } = useGetAllAttachments();
  const { sidebarFilters, searchTerm, setSearchTerm } =
    useSidebarFiltersWithPath();
  const { clearAttachments } = useAttachmentStore();
  const { pathname } = useLocation();
  const setAttachmentProject = useAttachmentStore(
    (state) => state.setAttachmentProject,
  );

  const selectedCollection = selectedCollectionByType[collectionType];

  const debounceSearch = useRef(
    debounce(
      async ({
        searchTerm,
        filters,
        isCollection,
        resourceIds,
      }: {
        filters: Record<string, any>;
        searchTerm: string;
        isCollection: boolean;
        resourceIds: string[];
      }) => {
        setIsSearching(true);
        setSearchTerm(searchTerm);
        abortRequest("searchAttachments");
        if (!isEmpty(searchTerm) || !isEmpty(filters)) {
          await searchAttachments({
            isCollection,
            resourceIds,
            searchTerm,
            filters: {
              ...filters,
              type: AllowedAttachmentType.pdf,
            },
          });
        } else {
          const args: any = {
            attachmentType: AllowedAttachmentType.pdf,
            limit: PaginationLimit.attachments,
          };
          if (isCollection) {
            args.ids = resourceIds;
          }

          await getAllAttachments(args);
        }
        setIsSearching(false);
      },
      DebounceTimes.Search,
    ),
  ).current;

  const handleSearch = async (
    searchTerm: string,
    sidebarFilters: Record<string, any> = {},
  ) => {
    await debounceSearch({
      searchTerm,
      isCollection,
      filters: transformFilters(sidebarFilters),
      resourceIds: selectedCollection?.resourceIds,
    });
  };

  const fetchAttachments = async (props: any) => {
    const args: any = {
      ...props,
      isCollection,
      attachmentType: AllowedAttachmentType.pdf,
      limit: PaginationLimit.attachments,
    };
    if (isCollection) {
      args.ids = selectedCollection?.resourceIds;
    }

    const attachments = await getAllAttachments({
      ...args,
      callback: (data: any) => {},
    });
    return attachments;
  };

  const fetchNextPage = useCallback(async () => {
    if (
      isSearchFilterEmpty(sidebarFilters) &&
      isEmpty(searchTerm) &&
      pagination?.hasNext &&
      pagination?.docsCount &&
      attachments.length < pagination.docsCount
    ) {
      const args: any = {
        next: pagination?.next,
        limit: PaginationLimit.attachments,
        attachmentType: AllowedAttachmentType.pdf,
      };
      if (isCollection) {
        args.ids = selectedCollection?.resourceIds;
      }

      await getAllAttachments(args);
    }
  }, [
    searchTerm,
    isCollection,
    sidebarFilters,
    attachments.length,
    pagination?.next,
    pagination?.hasNext,
    pagination?.docsCount,
    selectedCollection?.resourceIds,
    getAllAttachments,
  ]);

  useRunOnMountOnly(
    async () => {
      clearAttachments();
      if (!isEmpty(searchTerm) || !isSearchFilterEmpty(sidebarFilters)) {
        const filters = transformFilters(sidebarFilters);
        setIsSearching(true);
        await searchAttachments({
          isCollection,
          searchTerm,
          resourceIds: selectedCollection?.resourceIds,
          filters: {
            ...filters,
            type: AllowedAttachmentType.pdf,
          },
        });
        setIsSearching(false);
      } else {
        const args: any = {
          isCollection,
          attachmentType: AllowedAttachmentType.pdf,
          limit: PaginationLimit.attachments,
        };
        if (isCollection) {
          args.ids = selectedCollection?.resourceIds;
        }

        await getAllAttachments(args);
      }
    },
    JSON.stringify([isCollection, selectedCollection?.resourceIds]),
  );

  useRunOnMountOnly(
    () => {
      setAttachmentProject({ goBackRoute: pathname });
    },
    JSON.stringify([pathname]),
  );

  return (
    <MaxWidthLayout>
      <Helmet
        title={t(
          isCollection
            ? "Construction Details Collection"
            : "Construction Details",
        )}
      />
      <div className="flex h-screen flex-col px-4 py-1 lg:py-6">
        <ConstructionDetails
          canUpload={true}
          collectionType={collectionType}
          isCollection={isCollection}
          canAssignAttachments={true}
          canCreateCollection={true}
          attachments={attachments}
          pagination={pagination}
          fetchPaginatedAttachments={fetchNextPage}
          fetchAttachments={fetchAttachments}
          searchAttachments={handleSearch}
        />
      </div>
    </MaxWidthLayout>
  );
};
