import { FC } from "react";
import { MaterialListHeader } from "./MaterialListHeader";
import { MaterialListView } from "./MaterialListView";
import { MaterialsPageProps } from "./common";
import { SlideOver } from "@amenda-components/App";
import { useMaterialsStore } from "@amenda-domains/mutations";

export const MaterialList: FC<MaterialsPageProps> = (props) => {
  return (
    <aside className="hidden h-full flex-shrink-0 border-r border-gray-200 md:order-first md:flex md:w-72 md:flex-col lg:w-96">
      <MaterialListHeader {...props} />
      <MaterialListView {...props} />
    </aside>
  );
};

export const MaterialListMobile: FC<MaterialsPageProps> = (props) => {
  const openMaterialListSlideOver = useMaterialsStore(
    (state) => state.openMaterialListSlideOver,
  );
  const setOpenMaterialListSlideOver = useMaterialsStore(
    (state) => state.setOpenMaterialListSlideOver,
  );

  const handleClose = () => {
    setOpenMaterialListSlideOver(false);
  };

  return (
    <SlideOver show={openMaterialListSlideOver} onClose={handleClose}>
      <div className="relative h-screen w-full overflow-y-auto">
        <div className="sticky top-0 z-20 w-full bg-white">
          <MaterialListHeader {...props} />
        </div>
        <MaterialListView {...props} />
      </div>
    </SlideOver>
  );
};
