import { AvailableNotificationTypes, Pagination } from "@amenda-types";
import { createJSONStorage, persist } from "zustand/middleware";
import { gql, useMutation } from "urql";
import { groupBy, isEmpty, sortBy, uniq } from "lodash";

import { MATERIAL_FRAGMENT } from "@amenda-domains/fragments/materials";
import { create } from "zustand";
import { handleGroupedMaterials } from "@amenda-utils";
import { immer } from "zustand/middleware/immer";
import { useAppStore } from "./app";

interface Material {
  id: string;
  name: string;
  formValues: Record<string, any>;
  isDeleted?: boolean;
  ownerId?: string;
  share?: Record<string, any>;
}

const sortMaterials = (materials: Material[]) => {
  return sortBy(materials, [(material) => material?.name?.toLowerCase()]);
};

const groupMaterialsAlphabetically = (materials: Material[]) => {
  return groupBy(materials, (material) => {
    return material?.name?.toUpperCase().charAt(0);
  });
};

const groupAndSortMaterial = (materials: Material[] = []) => {
  const sortedMaterials = sortMaterials(materials);
  const groupedMaterials = groupMaterialsAlphabetically(sortedMaterials);

  return { groupedMaterials, selectedMaterial: sortedMaterials[0] };
};

const updateGroupedMaterials = (
  groupedMaterials: Record<string, any[]>,
  material: Record<string, any>,
) => {
  let updatedGroupedMaterials: Record<string, any[]> = {};

  Object.keys(groupedMaterials).forEach((key) => {
    updatedGroupedMaterials[key] = groupedMaterials[key].map((m) =>
      m.id === material.id ? material : m,
    );
  });
  return updatedGroupedMaterials;
};

type SetMaterialsArgs = {
  materials: Material[];
  autoSelect?: boolean;
  isCollection?: boolean;
} & Pagination;

type UpdateMaterialArgs = {
  materials: any[];
  autoSelect?: boolean;
  isCollection?: boolean;
  isGrouped?: boolean;
};

type State = {
  openMaterialListSlideOver: boolean;
  shiftSelectedMaterials: number[];
  selectedMaterial?: Material;
  selectedMaterialId?: string;
  materials: Material[];
  groupedMaterials: Record<string, Material[]>;
  isSubmitting: boolean;
  pagination: Pagination;
  selectedMaterialIds: string[];
  searchTerm: string;
  groupingComponents: any[];
  isSearchingMaterials: boolean;
  isFetchingMaterials: boolean;
};

type Actions = {
  setOpenMaterialListSlideOver: (open: boolean) => void;
  setShiftSelectedMaterial: (material: any) => void;
  toggleSelectedMaterial: (materialId: string) => void;
  setMaterials: (args: SetMaterialsArgs) => void;
  setSelectedMaterial: (material: Material) => void;
  clearSelectedMaterial: () => void;
  clearSelectedMaterialIds: () => void;
  setSearchTerm: (searchTerm: string) => void;
  toggleGroupingComponent: (component: any) => void;
  updateMaterials: (args: UpdateMaterialArgs) => void;
  setIsSearchingMaterials: (isSearchingMaterials: boolean) => void;
  setGetMaterialsLoader: (loading: boolean, pagination: Pagination) => void;
  updateMaterial: (material: any) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
};

const persistedStateVersion = 0.1;

export const useMaterialsStore = create(
  persist(
    immer<State & Actions>((set, get) => ({
      materials: [],
      groupedMaterials: {},
      pagination: {},
      selectedMaterialIds: [],
      groupingComponents: [],
      searchTerm: "",
      isSearchingMaterials: false,
      isFetchingMaterials: false,
      shiftSelectedMaterials: [],
      openMaterialListSlideOver: false,
      isSubmitting: false,
      setIsSubmitting: (isSubmitting) =>
        set((state) => {
          state.isSubmitting = isSubmitting;
        }),
      setOpenMaterialListSlideOver: (open) =>
        set((state) => {
          state.openMaterialListSlideOver = open;
        }),
      setShiftSelectedMaterial: (material) =>
        set((state) => {
          const index = get().materials.findIndex((m) => m.id === material.id);
          const materialsIndex = [...get().shiftSelectedMaterials, index];

          if (materialsIndex.length > 1) {
            const start = Math.min(...materialsIndex);
            const end = Math.max(...materialsIndex);

            let selectedMaterialIds = get()
              .materials.slice(start, end)
              .map((m) => m.id);
            selectedMaterialIds = uniq([
              ...selectedMaterialIds,
              ...get().selectedMaterialIds,
              material.id,
            ]);

            state.shiftSelectedMaterials = [];
            state.selectedMaterialIds = selectedMaterialIds;
          } else {
            state.shiftSelectedMaterials = [index];
            state.selectedMaterialIds = [material.id];
          }
        }),
      setGetMaterialsLoader: (loading, pagination) =>
        set((state) => {
          if (pagination?.next) {
            state.isFetchingMaterials = loading;
          } else {
            state.isSearchingMaterials = loading;
          }
        }),
      setIsSearchingMaterials: (isSearchingMaterials) =>
        set((state) => {
          state.isSearchingMaterials = isSearchingMaterials;
        }),
      updateMaterials: ({ materials, isGrouped, autoSelect }) =>
        set((state) => {
          if (!isGrouped) {
            const { groupedMaterials, selectedMaterial } =
              groupAndSortMaterial(materials);

            if (autoSelect) {
              state.selectedMaterial = selectedMaterial;
            }
            state.materials = materials;
            state.groupedMaterials = groupedMaterials;
          } else {
            const groupedMaterials = handleGroupedMaterials(materials);
            const selectedMaterial = materials?.[0]?.materials;

            state.materials = groupedMaterials;
            state.groupedMaterials = groupedMaterials;
            state.selectedMaterialId = selectedMaterial?._id;
          }
        }),
      setSearchTerm: (searchTerm) =>
        set((state) => {
          state.searchTerm = searchTerm;
        }),
      toggleGroupingComponent: (component) =>
        set((state) => {
          const components = get().groupingComponents;
          const hasComponent = components.find((c) => c.id === component.id);

          state.materials = [];
          state.groupedMaterials = {};
          state.selectedMaterialId = undefined;
          state.groupingComponents = hasComponent
            ? components.filter((c) => c.id !== component.id)
            : [...components, component];
        }),
      clearSelectedMaterialIds: () =>
        set((state) => {
          state.selectedMaterialIds = [];
        }),
      toggleSelectedMaterial: (materialId) =>
        set((state) => {
          let materialIds = get().selectedMaterialIds;
          if (materialIds.includes(materialId)) {
            materialIds = materialIds.filter((id) => id !== materialId);
          } else {
            materialIds = [...materialIds, materialId];
          }
          state.selectedMaterialIds = materialIds;
        }),
      clearSelectedMaterial: () =>
        set((state) => {
          state.selectedMaterial = undefined;
          state.selectedMaterialId = undefined;
        }),
      setSelectedMaterial: (material) =>
        set((state) => {
          state.selectedMaterial = material;
        }),
      updateMaterial: (material) =>
        set((state) => {
          if (get().selectedMaterialId === material.id) {
            state.selectedMaterial = material;
          }
          state.materials = get().materials.map((m) =>
            m.id === material.id ? material : m,
          );
          state.groupedMaterials = updateGroupedMaterials(
            get().groupedMaterials,
            material,
          );
        }),
      setMaterials: ({ autoSelect, materials, isCollection, ...pagination }) =>
        set((state) => {
          const { groupedMaterials, selectedMaterial } =
            groupAndSortMaterial(materials);

          state.materials = materials;
          state.pagination = pagination;
          state.groupedMaterials = groupedMaterials;

          const notInCollection =
            isCollection &&
            !materials.some(
              (material) => material?.id === get().selectedMaterialId,
            );
          if (
            autoSelect &&
            (isEmpty(get().selectedMaterialId) || notInCollection)
          ) {
            state.selectedMaterialId = selectedMaterial?.id;
          }
        }),
    })),
    {
      name: "materials-storage",
      version: persistedStateVersion,
      storage: createJSONStorage(() => sessionStorage),
      partialize: (state) => ({
        searchTerm: state.searchTerm,
        groupingComponents: state.groupingComponents,
        selectedMaterialIds: state.selectedMaterialIds,
      }),
    },
  ),
);

const CREATE_MATERIAL = gql`
  ${MATERIAL_FRAGMENT}
  mutation CreateMaterial($input: MaterialInput!) {
    createMaterial(input: $input) {
      ...MaterialFragment
    }
  }
`;

const UPDATE_MATERIAL = gql`
  ${MATERIAL_FRAGMENT}
  mutation UpdateMaterial($input: MaterialUpdateInput!) {
    updateMaterial(input: $input) {
      ...MaterialFragment
    }
  }
`;

export const useCreateMaterial = () => {
  const [result, callCreateMaterial] = useMutation(CREATE_MATERIAL);
  const showNotification = useAppStore((state) => state.showNotification);

  const createMaterial = (variables: Record<string, any>) => {
    return callCreateMaterial(variables)
      .then(({ data }) => {
        return data?.createMaterial;
      })
      .catch((error) => {
        showNotification(AvailableNotificationTypes.Error, error.message);
      });
  };
  return {
    createMaterial,
    loading: result.fetching,
  };
};

export const useUpdateMaterial = () => {
  const [result, callUpdateMaterial] = useMutation(UPDATE_MATERIAL);
  const showNotification = useAppStore((state) => state.showNotification);
  const updateMaterialInStore = useMaterialsStore(
    (state) => state.updateMaterial,
  );

  const updateMaterial = (variables: Record<string, any>) => {
    return callUpdateMaterial(variables)
      .then(({ data }) => {
        if (data?.updateMaterial) {
          updateMaterialInStore(data.updateMaterial);
        }
      })
      .catch((error) => {
        showNotification(AvailableNotificationTypes.Error, error.message);
      });
  };

  return {
    updateMaterial,
    loading: result.fetching,
  };
};

const DELETE_MATERIALS = gql`
  mutation DeleteMaterials($deleteRelatedResources: Boolean!, $ids: [ID!]!) {
    deleteMaterials(
      deleteRelatedResources: $deleteRelatedResources
      ids: $ids
    ) {
      id: _id
      isDeleted
    }
  }
`;

export const useDeleteMaterials = () => {
  const [result, callDeleteMaterials] = useMutation(DELETE_MATERIALS);
  const clearSelectedMaterialIds = useMaterialsStore(
    (state) => state.clearSelectedMaterialIds,
  );

  const deleteMaterials = async (variables: {
    ids: string[];
    deleteRelatedResources: boolean;
  }) => {
    return callDeleteMaterials(variables).then(({ data }) => {
      if (data?.deleteMaterials) {
        clearSelectedMaterialIds();
      }
    });
  };

  return {
    deleteMaterials,
    loading: result.fetching,
  };
};
