import { CircleSlash2, TrendingUp } from "lucide-react";
import { FC, useEffect, useState } from "react";

import { AppRoutes } from "@amenda-types";
import { HoverListSelector } from "@amenda-components/App";
import { MaterialsPageProps } from "./common";
import { formatNumbers } from "@amenda-utils";
import { useGetMaterialPrices } from "@amenda-domains/queries";
import { useInView } from "react-intersection-observer";
import { useMaterialsStore } from "@amenda-domains/mutations";
import { useNavigate } from "react-router-dom";

interface Props extends MaterialsPageProps {
  material: any;
}

export const MaterialCard: FC<Props> = ({
  material,
  isCollection,
  collectionRoute,
}) => {
  const { ref, inView } = useInView();
  const navigate = useNavigate();
  const selectedMaterialIds = useMaterialsStore(
    (state) => state.selectedMaterialIds,
  );
  const toggleSelectedMaterial = useMaterialsStore(
    (state) => state.toggleSelectedMaterial,
  );
  const setShiftSelectedMaterial = useMaterialsStore(
    (state) => state.setShiftSelectedMaterial,
  );
  const { getMaterialPrices } = useGetMaterialPrices();
  const [materialPrices, setMaterialPrices] = useState<Record<string, any>>();
  const setOpenMaterialListSlideOver = useMaterialsStore(
    (state) => state.setOpenMaterialListSlideOver,
  );

  const handleClick = () => {
    let path = isCollection ? collectionRoute : AppRoutes.Materials;
    path = `${path}/${material.id}`;

    setOpenMaterialListSlideOver(false);
    navigate(path);
  };

  const handleSelectMaterial = () => {
    toggleSelectedMaterial(material.id);
  };

  const handleShiftSelect = () => {
    setShiftSelectedMaterial(material);
  };

  useEffect(() => {
    const getMaterialPricesAsync = async () => {
      if (inView && material?.id) {
        await getMaterialPrices({
          id: material.id,
          callback: setMaterialPrices,
        });
      }
    };

    getMaterialPricesAsync();
  }, [inView, getMaterialPrices, material?.id]);

  return (
    <HoverListSelector
      isSelected={selectedMaterialIds.includes(material.id)}
      className="group flex w-full cursor-pointer items-center space-x-3 px-1 py-1 hover:bg-gray-50 md:px-6"
      handleClick={handleClick}
      handleSelect={handleSelectMaterial}
      handleShiftSelect={handleShiftSelect}
    >
      <div className="min-w-0 flex-1 pl-4" ref={ref}>
        <div className="text-gray-800">
          <p className="truncate text-base">{material.name}</p>
          <div className="flex items-center space-x-6 text-gray-500">
            <div className="flex items-center space-x-1">
              <CircleSlash2 className="h-4 w-4" />
              <div className="text-sm">
                <span>{formatNumbers(materialPrices?.averagePrice ?? 0)}</span>
                <span className="px-1">€</span>
              </div>
            </div>
            <div className="flex items-center space-x-1">
              <TrendingUp className="h-5 w-5" />
              <div className="text-sm">
                <span>{formatNumbers(materialPrices?.minPrice ?? 0)}</span>
                <span className="px-1">&ndash;</span>
                <span>{formatNumbers(materialPrices?.maxPrice ?? 0)}</span>
                <span className="px-1">€</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HoverListSelector>
  );
};
