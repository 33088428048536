import { AllowedContactType, AppRoutes } from "@amenda-types";
import { BuildingOfficeIcon, UserIcon } from "@heroicons/react/24/outline";
import { capitalize, isNil } from "lodash";
import { flattenUserDetails, getUserName } from "./common";

import { ArrowUpRightIcon } from "@heroicons/react/24/solid";
import { Chip } from "@amenda-components/App";
import { DeactivatedUsersAlert } from "./DeactivatedUsersAlert";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  contact?: any;
  roles?: string[];
}

export const ProjectContactCard: FC<Props> = ({ contact, roles }) => {
  const navigate = useNavigate();
  const user = flattenUserDetails(contact);

  const handleClick = () => {
    let path: string = isNil(contact?.type)
      ? AppRoutes.Colleagues
      : AppRoutes.Contacts;
    path = contact?.id ? `${path}/${contact.id}` : path;

    navigate(path);
  };

  return (
    <div
      className="group/projectContactCard mb-4 flex min-h-[4rem] cursor-pointer items-center space-x-2 border border-gray-200 px-1 py-2"
      onClick={handleClick}
    >
      <div className="relative min-w-0 flex-1">
        {!Boolean(contact?.type) && !Boolean(contact?.isActive) && (
          <div className="absolute h-full w-full bg-white/50 backdrop-sepia-0" />
        )}
        <div className="w-full overflow-hidden pl-1">
          <div className="flex">
            <div className="mr-2 mt-1 h-5 w-5 flex-none">
              {user?.type === AllowedContactType.company ? (
                <BuildingOfficeIcon fontSize={5} />
              ) : (
                <UserIcon fontSize={1} />
              )}
            </div>
            <div className="mt-1 w-64 flex-initial truncate">
              <div className="flex items-center">{getUserName(user)}</div>
              <p className="amenda-contact-subline hidden lg:block">
                {user?.address?.name}
              </p>
              <DeactivatedUsersAlert
                className="pt-1 text-sm"
                contact={contact}
              />
            </div>
          </div>
        </div>
        {roles && (
          <div className="mt-2 flex w-full flex-wrap pl-8">
            {(roles ?? []).map((role) => (
              <div className="mb-1 mr-1" key={role}>
                <Chip label={capitalize(role)} />
              </div>
            ))}
          </div>
        )}
      </div>
      <span
        className="pointer-events-none relative right-2 hidden justify-center text-[#2286e0] group-hover/projectContactCard:text-gray-900 lg:block"
        aria-hidden="true"
      >
        <ArrowUpRightIcon className="h-5 w-5" />
      </span>
    </div>
  );
};
