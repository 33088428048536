import {
  CalendarIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronsUpDownIcon,
  FileUpIcon,
  MailIcon,
  MapPinIcon,
  PhoneIcon,
  PlusIcon,
  SearchIcon,
} from "lucide-react";

import { Chip } from "@amenda-components/App";
import { FC } from "react";
import { FormComponentProps } from "@amenda-types";
import { FormComponentTypes } from "@amenda-constants";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

export const FormBuilderComponentField: FC<FormComponentProps> = ({
  config,
}) => {
  const { t } = useTranslation();
  const componentType = config.component as FormComponentTypes;

  if (
    [
      FormComponentTypes.Input,
      FormComponentTypes.AddressSearch,
      FormComponentTypes.CalculatorTextField,
    ].includes(componentType)
  ) {
    const isNumber = config.properties?.type === "number";
    const isEmail = config.properties?.type === "email";

    return (
      <div
        className={clsx(
          "mt-1 flex h-7 w-full items-center rounded-sm border border-gray-300 bg-gray-50",
          {
            "p-1": !isNumber,
            "justify-end": isNumber,
          },
        )}
      >
        {FormComponentTypes.AddressSearch === componentType && (
          <MapPinIcon className="h-4 w-4 text-gray-500" />
        )}
        {isEmail && <MailIcon className="h-4 w-4 text-gray-500" />}
        {isNumber && (
          <div className="border-l border-gray-300 p-1">
            <ChevronsUpDownIcon className="h-4 w-4 text-gray-500" />
          </div>
        )}
      </div>
    );
  } else if (FormComponentTypes.Textarea === componentType) {
    return (
      <div className="mt-1 h-16 w-full rounded-sm border border-gray-300 bg-gray-50" />
    );
  } else if (
    [
      FormComponentTypes.Select,
      FormComponentTypes.MultiSelect,
      FormComponentTypes.Keyword,
      FormComponentTypes.ColoredSelect,
      FormComponentTypes.SelectCostGroups,
    ].includes(componentType)
  ) {
    return (
      <div className="mt-1 flex h-7 w-full items-center justify-end rounded-sm border border-gray-300 bg-gray-50 p-1">
        <ChevronDownIcon className="h-4 w-4 text-gray-500" />
      </div>
    );
  } else if (FormComponentTypes.DatePicker === componentType) {
    return (
      <div className="mt-1 flex h-7 w-full items-center justify-start rounded-sm border border-gray-300 bg-gray-50 p-1">
        <CalendarIcon className="h-4 w-4 text-gray-500" />
      </div>
    );
  } else if (FormComponentTypes.DatePickerRange === componentType) {
    return (
      <div className="mt-1 grid h-7 w-full grid-cols-2 rounded-sm border border-gray-300 bg-gray-50">
        <div className="border-r border-gray-300 p-1">
          <CalendarIcon className="h-4 w-4 text-gray-500" />
        </div>
        <div className="p-1">
          <CalendarIcon className="h-4 w-4 text-gray-500" />
        </div>
      </div>
    );
  } else if (FormComponentTypes.RadioButton === componentType) {
    return (
      <div className="mt-1 flex h-7 w-full items-center space-x-2">
        <div className="h-4 w-4 rounded-full border border-gray-300 bg-gray-50" />
        <span className="text-xs text-gray-600">Value</span>
      </div>
    );
  } else if (FormComponentTypes.Checkbox === componentType) {
    return (
      <div className="mt-1 flex h-7 w-full items-center space-x-2">
        <div className="h-4 w-4 rounded-sm border border-gray-300 bg-gray-50" />
        <span className="text-xs text-gray-600">Value</span>
      </div>
    );
  } else if (FormComponentTypes.RegionalSelect === componentType) {
    return (
      <div className="mt-1 flex h-7 w-full items-center justify-between rounded-sm border border-gray-300 bg-gray-50 p-1">
        <div className="flex items-center space-x-1 text-xs text-gray-600">
          <SearchIcon className="h-3 w-3" />
          <span>{t("Region")}</span>
        </div>
        <ChevronDownIcon className="h-4 w-4 text-gray-500" />
      </div>
    );
  } else if (FormComponentTypes.SearchAndSelect === componentType) {
    return (
      <div className="mt-1 flex h-7 w-full items-center justify-between rounded-sm border border-gray-300 bg-gray-50 p-1">
        <div className="flex items-center space-x-1 text-xs text-gray-600">
          <SearchIcon className="h-3 w-3" />
          <span>{t("Contacts")}</span>
        </div>
        <ChevronDownIcon className="h-4 w-4 text-gray-500" />
      </div>
    );
  } else if (FormComponentTypes.SearchAndSelectProjects === componentType) {
    return (
      <div className="mt-1 flex h-7 w-full items-center justify-between rounded-sm border border-gray-300 bg-gray-50 p-1">
        <div className="flex items-center space-x-1 text-xs text-gray-600">
          <SearchIcon className="h-3 w-3" />
          <span>{t("Projects")}</span>
        </div>
        <ChevronDownIcon className="h-4 w-4 text-gray-500" />
      </div>
    );
  } else if (FormComponentTypes.MaterialPhoto === componentType) {
    return (
      <div className="mt-1 flex h-16 w-full items-center justify-center rounded-sm border border-dashed border-gray-300 bg-gray-50">
        <FileUpIcon className="h-6 w-6 text-gray-400" />
      </div>
    );
  } else if (FormComponentTypes.Badges === componentType) {
    return (
      <div className="mt-1 flex h-16 w-full items-center space-x-1">
        <Chip
          label="Label"
          className="rounded-sm border border-gray-300 bg-gray-50 text-xs"
          onDelete={() => {}}
        />
        <Chip
          label="Label 2"
          className="rounded-sm border border-gray-300 bg-gray-50 text-xs"
          onDelete={() => {}}
        />
      </div>
    );
  } else if (FormComponentTypes.LabelledInput === componentType) {
    return (
      <div className="mt-1 flex w-full items-center space-x-3">
        <div className="flex h-16 w-1/2 flex-col rounded-sm border border-gray-300 p-1 text-sm text-gray-500">
          <div className="flex items-center">
            <span className="text-xs">{t("Labels")}</span>
            <ChevronRightIcon className="h-3 w-3" />
          </div>
          <div className="mt-1 flex h-6 w-full items-center rounded-sm border border-gray-300 bg-gray-50 px-1">
            {config?.properties?.type === "phone" ? (
              <PhoneIcon className="h-4 w-4 text-gray-500" />
            ) : (
              <MailIcon className="h-4 w-4 text-gray-500" />
            )}
          </div>
        </div>
        <div>
          <div className="rounded-full border border-gray-300 bg-gray-50 p-1">
            <PlusIcon className="h-4 w-4 text-gray-600" />
          </div>
        </div>
      </div>
    );
  } else if (FormComponentTypes.LabelledContactInputs === componentType) {
    return (
      <div className="mt-1 flex w-full items-center space-x-3">
        <div className="flex h-16 w-1/2 flex-col rounded-sm border border-gray-300 p-1 text-sm text-gray-500">
          <div className="flex items-center">
            <span className="text-xs">{t("Labels")}</span>
            <ChevronRightIcon className="h-3 w-3" />
          </div>
          <div className="mt-1 flex h-6 w-full items-center rounded-sm border border-gray-300 bg-gray-50 px-1">
            <SearchIcon className="h-4 w-4 text-gray-500" />
            <span className="text-xs">{t("Contacts")}</span>
          </div>
        </div>
        <div>
          <div className="rounded-full border border-gray-300 bg-gray-50 p-1">
            <PlusIcon className="h-4 w-4 text-gray-600" />
          </div>
        </div>
      </div>
    );
  } else if (FormComponentTypes.Switch === componentType) {
    return (
      <div className="mt-1 w-full">
        <div className="w-10 rounded-lg border border-gray-300 bg-gray-50 p-0.5">
          <div className="h-4 w-4 rounded-lg bg-gray-300" />
        </div>
      </div>
    );
  } else if (FormComponentTypes.MaterialCostInput === componentType) {
    return (
      <div className="mt-1 flex w-full justify-between space-x-1">
        <div className="w-1/2 pr-4">
          <span className="truncate text-xs">Gesamt Abnahmemenge</span>
          <div className="mt-1 flex h-7 w-full items-center justify-end rounded-sm border border-gray-300 bg-gray-50 p-1">
            <div className="border-l border-gray-300 p-1">
              <ChevronsUpDownIcon className="h-4 w-4 text-gray-500" />
            </div>
          </div>
        </div>
        <div className="w-1/2 pr-4">
          <span className="truncate text-xs">Einheitspreis</span>
          <div className="mt-1 flex h-7 w-full items-center justify-end rounded-sm border border-gray-300 bg-gray-50 p-1">
            <div className="border-l border-gray-300 p-1">
              <ChevronsUpDownIcon className="h-4 w-4 text-gray-500" />
            </div>
          </div>
        </div>
        <div className="w-1/2 pr-4">
          <span className="truncate text-xs">Quartal der Ermittlung</span>
          <div className="mt-1 flex h-7 w-full items-center justify-start rounded-sm border border-gray-300 bg-gray-50 p-1">
            <CalendarIcon className="h-4 w-4 text-gray-500" />
          </div>
        </div>
        <div className="w-1/2 pr-4">
          <span className="truncate text-xs">Quelle</span>
          <div className="mt-1 flex h-7 w-full items-center justify-end rounded-sm border border-gray-300 bg-gray-50 p-1">
            <ChevronDownIcon className="h-4 w-4 text-gray-500" />
          </div>
        </div>
        <div className="w-1/2 pr-4">
          <span className="truncate text-xs">Status:archiviert</span>
          <div className="mt-1 flex h-7 w-full items-center space-x-2">
            <div className="h-4 w-4 rounded-sm border border-gray-300 bg-gray-50" />
            <span className="text-xs text-gray-600">Value</span>
          </div>
        </div>
      </div>
    );
  } else if (FormComponentTypes.MaterialPercentageValues === componentType) {
    return (
      <div className="mt-1 flex w-full justify-between space-x-1">
        <div className="w-1/2 pr-4">
          <span className="truncate text-xs">{t("Percentage Share")}</span>
          <div className="mt-1 flex h-7 w-full items-center justify-end rounded-sm border border-gray-300 bg-gray-50 p-1">
            <div className="border-l border-gray-300 p-1">
              <ChevronsUpDownIcon className="h-4 w-4 text-gray-500" />
            </div>
          </div>
        </div>
        <div className="w-1/2 pr-4">
          <span className="truncate text-xs">Quartal der Ermittlung</span>
          <div className="mt-1 flex h-7 w-full items-center justify-start rounded-sm border border-gray-300 bg-gray-50 p-1">
            <CalendarIcon className="h-4 w-4 text-gray-500" />
          </div>
        </div>
        <div className="w-1/2 pr-4">
          <span className="truncate text-xs">Quelle</span>
          <div className="mt-1 flex h-7 w-full items-center justify-end rounded-sm border border-gray-300 bg-gray-50 p-1">
            <ChevronDownIcon className="h-4 w-4 text-gray-500" />
          </div>
        </div>
        <div className="w-1/2 pr-4">
          <span className="truncate text-xs">Status:archiviert</span>
          <div className="mt-1 flex h-7 w-full items-center space-x-2">
            <div className="h-4 w-4 rounded-sm border border-gray-300 bg-gray-50" />
            <span className="text-xs text-gray-600">Value</span>
          </div>
        </div>
      </div>
    );
  }
  return null;
};
