import { FC, ReactNode } from "react";

import { MiniCard } from "@amenda-components/Shared";
import { isRestricted } from "@amenda-utils";
import { useNavigate } from "react-router-dom";
import { useProjectStore } from "@amenda-domains/mutations";

interface Props {
  componentsById: Record<string, any>;
  permissions: Record<string, any>;
  project: any;
  children?: ReactNode;
  isFullHeight?: boolean;
}

export const ProjectMiniCard: FC<Props> = ({
  componentsById,
  permissions,
  project,
  children,
  isFullHeight,
}) => {
  const navigate = useNavigate();
  const setSelectedProject = useProjectStore(
    (state) => state.setSelectedProject,
  );

  const projectName = componentsById["name"];
  const projectNumber = componentsById["number"];
  const projectDescription = componentsById["description"];

  const handleClick = () => {
    setSelectedProject({});
    navigate(`/projekte/${project?.id}`);
  };

  return (
    <div className="w-full cursor-pointer" onClick={handleClick}>
      <MiniCard
        image={project.galleryUrl}
        projectName={!isRestricted(projectName, permissions) && project?.name}
        projectNumber={
          !isRestricted(projectNumber, permissions) && project?.number
        }
        projectDescription={
          !isRestricted(projectDescription, permissions) && project.description
        }
        isFullHeight={isFullHeight}
        enableHighlighting={true}
      >
        {children}
      </MiniCard>
    </div>
  );
};
