import {
  Button,
  HelperMessage,
  LoaderWrapper,
  Tooltip,
} from "@amenda-components/App";
import { ChevronRightIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { FC, useEffect, useRef, useState } from "react";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import { useFormStore, useUsersStore } from "@amenda-domains/mutations";
import {
  useGetAllCollections,
  useGetCollection,
} from "@amenda-domains/queries";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { CollectionProps } from "./common";
import { CreateCollectionModal } from "./CreateCollectionModal";
import { DebounceTimes } from "@amenda-constants";
import { EditCollectionModal } from "./EditCollectionModal";
import { MiniSearchField } from "@amenda-components/SearchComponents";
import { PencilIcon } from "@heroicons/react/24/outline";
import { SidebarToggle } from "@amenda-components/Shared";
import clsx from "clsx";
import { debounce } from "lodash";
import { inputFieldTheme } from "@amenda-styles/theme";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

interface Props extends CollectionProps {
  label: string;
  goBackLabel: string;
  className?: string;
  wrapperClassName?: string;
  routes: {
    goBack: string;
    collection: string;
  };
}

export const CollectionDropdown: FC<Props> = ({
  label,
  routes,
  className,
  resourceIds,
  goBackLabel,
  collectionType,
  getResourceIds,
  clearSelectedResources,
  wrapperClassName = "",
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { collectionId } = useParams<{ collectionId: string }>();
  const [searchTerm, setSearchTerm] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const { getCollection } = useGetCollection();
  const { getAllCollections, loading } = useGetAllCollections();
  const collectionsByType = useFormStore((state) => state.collectionsByType);
  const selectedCollectionByType = useFormStore(
    (state) => state.selectedCollectionByType,
  );
  const setSelectedCollectionByType = useFormStore(
    (state) => state.setSelectedCollectionByType,
  );
  const clearSelectedCollectionByType = useFormStore(
    (state) => state.clearSelectedCollectionByType,
  );
  const currentUser = useUsersStore((state) => state.currentUser);
  const { searchInputCss } = inputFieldTheme();

  const openEditModal = pathname.endsWith(
    `/sammlungen/${collectionId}/bearbeiten`,
  );
  const selectedCollection = selectedCollectionByType[collectionType];
  const collections = collectionsByType[collectionType];

  const debounceSearch = useRef(
    debounce(async (searchTerm: string) => {
      await getAllCollections({
        collectionType,
        isDeleted: false,
        context: {
          requestPolicy: "network-only",
        },
        ...(isEmpty(searchTerm)
          ? {}
          : {
              name: searchTerm,
            }),
      });
    }, DebounceTimes.Search),
  ).current;

  const handleSearch = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
    await debounceSearch(searchTerm);
  };

  const handleNew = (close: () => void) => () => {
    setOpenModal(true);
    close();
  };

  const handleSelectCollection = (collection: any, close: () => void) => () => {
    setSelectedCollectionByType(collection);
    close();
    navigate(`${routes.collection}/${collection.id}`);
  };

  const goBack = (close: () => void) => () => {
    clearSelectedCollectionByType(collectionType);
    close();
    navigate(routes.goBack);
  };

  const handleOpenEditModal =
    (collection: any, close: () => void) => (e: any) => {
      e.stopPropagation();

      if (selectedCollection?.id !== collection.id) {
        setSelectedCollectionByType(collection);
      }
      close();
      navigate(`${routes.collection}/${collection.id}/bearbeiten`);
    };

  const handleCloseEditModal = () => {
    navigate(-1);
  };

  useEffect(() => {
    getAllCollections({
      collectionType,
      isDeleted: false,
    });
  }, [getAllCollections, collectionType]);

  useEffect(() => {
    if (collectionId) {
      getCollection({
        id: collectionId,
      });
    }
  }, [getCollection, collectionId]);

  return (
    <div className={clsx("flex", className)}>
      <CreateCollectionModal
        isOpen={openModal}
        resourceIds={resourceIds}
        collectionType={collectionType}
        collectionRoute={routes.collection}
        getResourceIds={getResourceIds}
        handleClose={() => setOpenModal(false)}
        clearSelectedResources={clearSelectedResources}
      />
      <EditCollectionModal
        isOpen={openEditModal}
        goBackRoute={routes.goBack}
        collectionType={collectionType}
        getResourceIds={getResourceIds}
        handleClose={handleCloseEditModal}
      />
      <Popover as="div" className="relative w-full">
        {({ open }) => (
          <>
            <div className={clsx("flex w-full items-center", wrapperClassName)}>
              <SidebarToggle />
              <PopoverButton className="flex flex-row items-center outline-none">
                <h1 className="amenda-page-title truncate">
                  {selectedCollection?.name
                    ? selectedCollection?.name
                    : t(label)}
                </h1>
                <Tooltip
                  id="toggleCollectionDropdown"
                  message="Show Collections"
                  position="bottom"
                >
                  <ChevronRightIcon
                    className={clsx(
                      "h-5 w-5 rounded-full bg-gray-800 p-1 text-white",
                      {
                        "rotate-90": open,
                      },
                    )}
                  />
                </Tooltip>
              </PopoverButton>
            </div>

            {selectedCollection?.description && (
              <span className="flex flex-col text-sm">
                {selectedCollection?.description}
              </span>
            )}
            <Transition
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <PopoverPanel
                as="div"
                className="fixed z-50 w-80 origin-top-left divide-y divide-gray-100 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                {({ close }) => (
                  <div className="w-full px-2 py-2">
                    <div className="max-h-80 min-h-[10rem] overflow-auto">
                      <div className="border-1 sticky bottom-0 bg-white text-xs text-gray-500">
                        {(!isEmpty(collections) || Boolean(searchTerm)) && (
                          <MiniSearchField
                            value={searchTerm}
                            className={searchInputCss({
                              size: "sm",
                              class: "w-full border-gray-100 bg-gray-50",
                            })}
                            placeholder={t("Search collections") + "..."}
                            onChange={handleSearch}
                          />
                        )}
                      </div>
                      <div className="my-2 flex flex-col space-y-1">
                        {loading ? (
                          <LoaderWrapper className="h-24" spinnerSize="sm" />
                        ) : (
                          <>
                            {isEmpty(collections) ? (
                              <div className="pt-6">
                                <HelperMessage
                                  className="h-24"
                                  iconClassName="h-8 w-8 text-gray-800"
                                  message="No collection found. Collection help you to organize your data. Create a new one and share with your team."
                                />
                              </div>
                            ) : (
                              <>
                                {selectedCollection?.id && (
                                  <button
                                    onClick={goBack(close)}
                                    className="w-full bg-white px-4 py-2.5 text-left text-sm text-gray-800 hover:bg-gray-900 hover:text-white"
                                  >
                                    <span>{t(goBackLabel)}</span>
                                  </button>
                                )}
                                {collections.map((collection) => (
                                  <button
                                    key={collection.id}
                                    onClick={handleSelectCollection(
                                      collection,
                                      close,
                                    )}
                                    className={clsx(
                                      "flex w-full items-center justify-between px-4 py-2.5 text-left text-sm hover:bg-gray-900 hover:text-white",
                                      {
                                        "bg-white text-gray-800":
                                          collection.id !==
                                          selectedCollection?.id,
                                        "bg-gray-600 text-white":
                                          collection.id ===
                                          selectedCollection?.id,
                                      },
                                    )}
                                  >
                                    <span>{collection.name}</span>
                                    {currentUser?.id ===
                                      collection?.ownerId && (
                                      <span
                                        className="z-10 cursor-pointer p-1 transition-colors hover:bg-gray-700"
                                        onClick={handleOpenEditModal(
                                          collection,
                                          close,
                                        )}
                                      >
                                        <PencilIcon className="h-5 w-5" />
                                      </span>
                                    )}
                                  </button>
                                ))}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="border-t border-gray-100 pb-1 pt-2"></div>
                    <Button
                      variant="default"
                      className="flex w-full justify-end"
                      onClick={handleNew(close)}
                    >
                      <span className="pr-2 pt-1">
                        {t("Create new collection")}
                      </span>
                      <PlusCircleIcon className="h-6 w-6" />
                    </Button>
                  </div>
                )}
              </PopoverPanel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};
