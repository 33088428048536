import {
  ActionBar,
  Button,
  Chip,
  FullScreenModal,
  ModalBaseTitle,
  SaveIndicator,
  Tooltip,
} from "@amenda-components/App";
import { AppRoutes, GeneralPermissionTypes } from "@amenda-types";
import { CameraIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import { MainContainer, SidebarContainer } from "@amenda-components/Shared";
import { MaterialsPageProps, isAddingNewMaterial } from "./common";
import { Share2Icon, XIcon } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMaterialsStore, useSettingsStore } from "@amenda-domains/mutations";

import Webcam from "react-webcam";
import axios from "axios";
import { devConsole } from "@amenda-utils";
import jsQR from "jsqr";
import { useTranslation } from "react-i18next";

interface Props extends MaterialsPageProps {
  materialId?: string;
  setOpenModal: (open: boolean) => void;
  setOpenShareModal: (open: boolean) => void;
}

const VISION_API_KEY = "da10353c90694f278e7b77baa7d05c25"; // Replace with your Vision API key
const VISION_API_ENDPOINT =
  "https://amendavisionapi.cognitiveservices.azure.com"; // Replace with your Vision API endpoint

const analyzeImage = async (imageData: string) => {
  const base64Image = imageData.split(",")[1];
  const binaryData = atob(base64Image);
  const length = binaryData.length;
  const arrayBuffer = new ArrayBuffer(length);
  const uintArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < length; i++) {
    uintArray[i] = binaryData.charCodeAt(i);
  }

  const blob = new Blob([arrayBuffer], { type: "image/jpeg" });

  const response = await axios.post(
    `${VISION_API_ENDPOINT}/vision/v3.2/analyze?visualFeatures=Description,Tags`,
    blob,
    {
      headers: {
        "Ocp-Apim-Subscription-Key": VISION_API_KEY,
        "Content-Type": "application/octet-stream",
      },
    },
  );

  return response.data;
};

const readQRCode = (imageData: string) => {
  const base64Image = imageData.split(",")[1];
  const binaryData = atob(base64Image);
  const length = binaryData.length;
  const arrayBuffer = new ArrayBuffer(length);
  const uintArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < length; i++) {
    uintArray[i] = binaryData.charCodeAt(i);
  }

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  if (context) {
    const img = new Image();
    img.src = imageData;
    return new Promise<string | null>((resolve) => {
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0, img.width, img.height);
        const imageData = context.getImageData(0, 0, img.width, img.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height);
        resolve(code ? code.data : null);
      };
    });
  }
  return Promise.resolve(null);
};

export const MaterialActionBar: FC<Props> = ({
  collectionRoute,
  isCollection,
  materialId,
  setOpenModal,
  setOpenShareModal,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const isSubmitting = useMaterialsStore((state) => state.isSubmitting);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [usingWebcam, setUsingWebcam] = useState(false);
  const [capturedImage, setCapturedImage] = useState<string | null>(null);
  const [countdown, setCountdown] = useState<number | null>(null);
  const [imageDescription, setImageDescription] = useState<string | null>(null);
  const [imageTags, setImageTags] = useState<
    { name: string; confidence: number }[]
  >([]);
  const [qrCodeContent, setQrCodeContent] = useState<string | null>(null);

  const unitPriceGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.UnitPrice,
  );
  const isEditing =
    isAddingNewMaterial(pathname) ||
    (materialId && pathname.endsWith(`/${materialId}/bearbeiten`));
  const showActions = !isAddingNewMaterial(pathname) && !!materialId;
  const path = isCollection ? collectionRoute : AppRoutes.Materials;

  const { t } = useTranslation();

  const handleEdit = () => {
    navigate(`${path}/${materialId}/bearbeiten`);
  };

  const handleReadOnly = () => {
    navigate(`${path}/${materialId}`);
  };

  const handleDelete = () => setOpenModal(true);

  const handleUploadImage = () => setOpenUploadModal(true);

  const webcamRef = useRef<Webcam>(null);

  const handleCaptureImage = useCallback(async () => {
    if (webcamRef.current) {
      const imageSrc: string | null = webcamRef.current.getScreenshot();
      if (imageSrc) {
        setCapturedImage(imageSrc);
        setUsingWebcam(false); // Stop using webcam after capturing image
        setCountdown(null);

        // Analyze the image using the Vision API
        try {
          const analysisResult = await analyzeImage(imageSrc);
          setImageDescription(analysisResult.description.captions[0].text);
          setImageTags(analysisResult.tags);

          // Read QR code from the image
          const qrCodeResult = await readQRCode(imageSrc);
          setQrCodeContent(qrCodeResult);
        } catch (error) {
          devConsole?.error("Error analyzing image:", error);
          setImageDescription("Error analyzing image.");
        }
      }
    }
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      handleCaptureImage();
    } else if (countdown !== null) {
      const timer = setTimeout(() => {
        setCountdown((prev) => (prev !== null ? prev - 1 : null));
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown, handleCaptureImage]);

  const startCountdown = () => {
    setCountdown(3);
  };

  const handleRetake = () => {
    setCapturedImage(null);
    setImageDescription(null);
    setQrCodeContent(null);
    setUsingWebcam(true);
  };

  const handleContinue = () => {
    setOpenUploadModal(false);
    setCapturedImage(null);
    setImageDescription(null);
    setQrCodeContent(null);
  };

  return (
    <ActionBar>
      {({ IconButton }) => {
        return (
          <>
            {isEditing ? (
              <>
                <Tooltip id="uploadImage" message={t("Upload Image")}>
                  <IconButton
                    Icon={CameraIcon}
                    label={t("Upload Image")}
                    variant="default"
                    onClick={handleUploadImage}
                  />
                </Tooltip>
                <SaveIndicator isSubmitting={isSubmitting} />
                <Tooltip id="back" message="Close">
                  <IconButton
                    Icon={XIcon}
                    label={t("Close")}
                    variant="default"
                    onClick={handleReadOnly}
                  />
                </Tooltip>
              </>
            ) : (
              <>
                {unitPriceGeneralPermissions[GeneralPermissionTypes.Share] && (
                  <Tooltip id="share" message={t("Share")}>
                    <IconButton
                      Icon={Share2Icon}
                      label={t("Share")}
                      variant="default"
                      onClick={() => setOpenShareModal(true)}
                    />
                  </Tooltip>
                )}
                {unitPriceGeneralPermissions[GeneralPermissionTypes.Delete] &&
                  showActions && (
                    <IconButton
                      Icon={TrashIcon}
                      label={t("Delete material")}
                      variant="default"
                      onClick={handleDelete}
                    />
                  )}
                {unitPriceGeneralPermissions[GeneralPermissionTypes.Edit] &&
                  showActions && (
                    <IconButton
                      Icon={PencilIcon}
                      label={t("Edit material")}
                      variant="default"
                      onClick={handleEdit}
                    />
                  )}
              </>
            )}
            <FullScreenModal
              isOpen={openUploadModal}
              onClose={() => setOpenUploadModal(false)}
            >
              <MainContainer>
                <ModalBaseTitle
                  className="mb-2 pt-1 font-sans"
                  title={t("Capture Image")}
                />
                <div className="flex h-screen w-full flex-col items-center justify-center p-4">
                  {usingWebcam || !capturedImage ? (
                    <div className="flex flex-col items-center">
                      <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width={1280}
                        height={720}
                      />
                      {countdown !== null ? (
                        <div className="mt-2 text-2xl">{countdown}</div>
                      ) : (
                        <Button
                          className="btn btn-primary mt-2"
                          variant="primary"
                          onClick={startCountdown}
                        >
                          {t("Capture Photo")}
                        </Button>
                      )}
                    </div>
                  ) : (
                    <div className="flex h-full w-full flex-row">
                      <div className="flex w-full flex-col items-center">
                        <div className="mt-4">
                          <img
                            src={capturedImage}
                            alt="Captured"
                            className="mt-2"
                          />
                          {imageDescription && (
                            <p className="mt-2 text-lg">{imageDescription}</p>
                          )}
                        </div>
                      </div>
                      <SidebarContainer className="relative w-96 border-l">
                        {imageTags.length > 0 && (
                          <div className="mt-2 px-2">
                            <h4 className="text-md">{t("Tags")}</h4>
                            {imageTags.map((tag, index) => (
                              <Chip
                                key={index}
                                label={tag.name}
                                className="border-1 m-1 flex max-h-[30px] items-center justify-center rounded-none border bg-white py-[7px] text-xs text-[#797F88]"
                                onDelete={() => {}}
                              />
                            ))}

                            {qrCodeContent && (
                              <div className="mt-2 px-2">
                                <h4 className="text-md">{t("QR Code")}</h4>
                                <input
                                  type="text"
                                  value={qrCodeContent}
                                  onChange={(e) =>
                                    setQrCodeContent(e.target.value)
                                  }
                                  className="mt-2 border px-2 py-1 text-sm"
                                />
                              </div>
                            )}
                          </div>
                        )}
                        <div className="mt-4 flex space-x-4">
                          <Button variant="default" onClick={handleRetake}>
                            {t("Retake")}
                          </Button>
                          <Button variant="primary" onClick={handleContinue}>
                            {t("Continue")}
                          </Button>
                        </div>
                      </SidebarContainer>
                    </div>
                  )}
                </div>
              </MainContainer>
            </FullScreenModal>
          </>
        );
      }}
    </ActionBar>
  );
};
