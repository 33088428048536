import { ComponentsTreeViewRowProps } from "@amenda-components/PageBuilder";
import { FC } from "react";
import { QuantityEstimateColumns } from "./common";
import { TextField } from "@amenda-components/FormComponents";
import { Tooltip } from "@amenda-components/App";
import { XCircleIcon } from "lucide-react";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";

const QuantityEstimate: FC<ComponentsTreeViewRowProps & { id: string }> = ({
  id,
  control,
  column,
  component,
  globalData,
}) => {
  const { t } = useTranslation();
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    control,
    name: id,
  });

  if (column.id === QuantityEstimateColumns.Placeholder) {
    return (
      <div className="invisible flex h-full items-center justify-end px-2 group-hover/treeRow:visible">
        <button
          type="button"
          className="group/clearBtn flex h-8 w-8 items-center justify-center rounded-full text-gray-800 hover:text-gray-900"
          onClick={() => onChange(NaN)}
        >
          <XCircleIcon
            className="h-5 w-5 group-hover/clearBtn:h-7 group-hover/clearBtn:w-7"
            aria-hidden="true"
          />
          <span className="sr-only">{t("Clear values for this row")}</span>
        </button>
      </div>
    );
  }

  const message =
    globalData?.missingQuantities?.includes(component.id) &&
    !Number.isFinite(value)
      ? "This value is being referenced in costs, enter quantity to enable calculations"
      : error?.message;

  return (
    <div className="flex w-full justify-end">
      <div className="max-w-sm md:min-w-48">
        <Tooltip id={id} position="right" className="w-full" message={message}>
          <TextField
            id={id}
            value={value}
            type="number"
            size="xs"
            isClearable={false}
            inputWrapperClassName="flex-row"
            inputClassName="text-xs text-right pr-8"
            error={message}
            hideErrorMessage={true}
            endAddOnText={component?.properties?.endAddOnText}
            onChange={onChange}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export const QuantityEstimateBody: FC<ComponentsTreeViewRowProps> = ({
  component,
  ...rest
}) => {
  const id = `considerationValues.${component.id}`;

  return <QuantityEstimate key={id} id={id} component={component} {...rest} />;
};
