import { FC } from "react";
import { GripIcon } from "lucide-react";
import { IconButtonBase } from "@amenda-components/App";
import { LayoutProps } from "./common";
import { SortItem } from "./DragAndDropWrapper";

export const OneThirdColumn: FC<LayoutProps> = ({
  config,
  children,
  isFormBuilder,
}) => {
  return (
    <SortItem
      className="group/one-third-col col-span-4 md:col-span-2"
      config={config}
      isFormBuilder={isFormBuilder}
    >
      {isFormBuilder && (
        <IconButtonBase
          size="sm"
          className="invisible cursor-grab px-0.5 py-1 group-hover/one-third-col:visible"
          variant="clean"
        >
          <GripIcon className="h-4 w-4 stroke-[1.5]" />
        </IconButtonBase>
      )}
      {children}
    </SortItem>
  );
};
