import {
  AllowedCostGroupTypes,
  AvailableForms,
  DebounceTimes,
} from "@amenda-constants";
import {
  Button,
  HelperMessage,
  LoaderWrapper,
  Modal,
  ResourceSelector,
} from "@amenda-components/App";
import { FC, useRef, useState } from "react";
import {
  MiniSearchField,
  ProjectCard,
} from "@amenda-components/SearchComponents";
import {
  convertEstimatesToCostGroupData,
  getConsiderationDate,
} from "./common";
import { debounce, isEmpty } from "lodash";
import { useCreateCostGroup, useProjectStore } from "@amenda-domains/mutations";

import { BuildingOffice2Icon } from "@heroicons/react/24/outline";
import { HoverVariants } from "@amenda-types";
import { getComponentByCode } from "@amenda-components/CostGroup/common";
import { getComponentsFromForms } from "@amenda-utils";
import { useSearchProjects } from "@amenda-domains/queries";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen: boolean;
  costEstimationModalValues: {
    region: any;
    estimationMode: boolean;
    considerationDate: string;
    considerationValues: Record<string, number>;
    selectedCostGroups: string[];
  };
  closeModal: () => void;
}

const date = new Date().toISOString();

export const CostEstimateExistingProjectModal: FC<Props> = ({
  isOpen,
  costEstimationModalValues,
  closeModal,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [projects, setProjects] = useState<any[]>([]);
  const [selectedProjectId, setSelectedProjectId] = useState<string>();
  const costEstimateValues = useProjectStore(
    (state) => state.costEstimateValues,
  );
  const { createCostGroup, loading: isSubmitting } = useCreateCostGroup();
  const forms = useProjectStore((state) => state.forms) || {};
  const { searchProjects, loading } = useSearchProjects();
  const latestBKIDate = useProjectStore((state) => state.latestBKIDate);

  const costGroupComponents = getComponentsFromForms(
    forms[AvailableForms.CostGroupsCost] || [],
  );
  const { componentByCode } = getComponentByCode(costGroupComponents);

  const debounceSearch = useRef(
    debounce(async (searchTerm: string) => {
      if (!isEmpty(searchTerm)) {
        await searchProjects({
          searchTerm,
          callback: (projects) => setProjects(projects ?? []),
        });
      }
    }, DebounceTimes.Search),
  ).current;

  const handleSearch = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
    await debounceSearch(searchTerm);
  };

  const handleSubmit = async () => {
    const { considerationDate, ...rest } = costEstimationModalValues;
    if (selectedProjectId) {
      const data = convertEstimatesToCostGroupData({
        ...rest,
        componentByCode,
        costEstimateValues,
      });

      await createCostGroup({
        input: {
          ...data,
          versionDate: date,
          resourceId: selectedProjectId,
          type: AllowedCostGroupTypes.Rollover,
          bkiDate: getConsiderationDate(
            considerationDate,
            latestBKIDate.dateIso,
          ),
        },
      });
    }
    closeModal();
  };

  return (
    <Modal
      size="md"
      successLabel="Submit"
      className="w-11/12 md:w-1/2 lg:w-4/12"
      title="Add cost estimation to project"
      isOpen={isOpen}
      loading={isSubmitting}
      closeModalFromTitle={true}
      onClose={closeModal}
      footerChildren={({ loading, onClose }) => {
        return (
          <>
            <Button className="mr-1" type="button" onClick={onClose}>
              {t("Cancel")}
            </Button>
            <Button
              loading={loading}
              type="button"
              variant="primary"
              disabled={isEmpty(selectedProjectId)}
              onClick={handleSubmit}
            >
              {t("Save")}
            </Button>
          </>
        );
      }}
    >
      <div className="w-full">
        <MiniSearchField
          className="bg-gray-100"
          placeholder="Search projects"
          value={searchTerm}
          onChange={handleSearch}
        />
        {loading ? (
          <LoaderWrapper className="h-32" spinnerSize="sm" />
        ) : isEmpty(projects) ? (
          <HelperMessage
            className="h-32"
            iconClassName="h-8 w-8 text-gray-800"
            Icon={BuildingOffice2Icon}
            message="No projects found"
          />
        ) : (
          <div className="flex max-h-[70vh] flex-col space-y-2 overflow-y-auto pt-4">
            {projects.map((project) => (
              <ResourceSelector
                key={project.id}
                id={project.id}
                isSelected={selectedProjectId === project.id}
                onClick={() => setSelectedProjectId(project.id)}
                selectorType={HoverVariants.RadioButton}
              >
                <ProjectCard key={project.id} project={project} />
              </ResourceSelector>
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
};
